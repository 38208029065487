@media only screen and (max-width:768px) {
    .header {
        display: block;
        text-align: center;
        padding: 20px 0 0;
    }
    
    .header-text a {
        font-size: 24px;
    }

    .menu {
        text-align: center;

        a {
            font-size: 18px;
            padding: 0 12px;
        }
    }

    .content {
        width: 100%;
        padding: 20px 10px;

        &-body {
            width: 100%;
        }
    }

    .gallery > .gallery__list {
        grid-template-columns: repeat(3, minmax(auto, 1fr));
        grid-template-rows: repeat(24, 200px);
    }

    .flex {
        display: block;
    }

    .about-pic {
        width: 100%;
        height: auto;
        margin: 0 0 20px;
    }

    #about {
    .background {
        position: absolute;
        top: 0;
        width: 100vw;
        height: 100vh;
        background: url(../../../assets/img/home-town.jpg) center / cover;
        opacity: .2;
        z-index: 1;
    }
    }
}
@media only screen and (min-width:480px) {

}
@media only screen and (min-width:768px) {

}
@media only screen and (min-width:1024px) {

}
@media only screen and (min-width:1140px) {

}
@media only screen and (min-width:1280px) {

}
@media print, (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {

}
