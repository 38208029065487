* {
  box-sizing: border-box;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

body,html{
	min-height: 100vh;
	overflow: hidden;
}

body {
  background: #111;
}

.wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 2;
}

.background {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: url(../../../assets/img/bg.jpg);
  background-size: 100%;
  opacity: .4;
  z-index: 1;
}

#about {
  .background {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: url(../../../assets/img/home-town.jpg) center;
    background-size: 100%;
    opacity: .4;
    z-index: 1;
  }
}

#video {
  .background {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: url(../../../assets/img/steel.jpg) center;
    background-size: 100%;
    opacity: .4;
    z-index: 1;
  }
}