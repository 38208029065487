a {
    color: #fff;
}

.header {
    display: flex;
    width: 100%;
    min-height: 75px;
    padding: 0 25px;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    background: rgba(0,0,0,.1);

    &-text a {
        width: auto;
        font: 30px 'Josefin Sans', arial, helvetica, sans-serif;
        color: #ddd;
        text-decoration: none;
        font-weight: 700;
        margin-bottom: -5px;
    }
}

.menu {
    width: auto;
    text-align: right;

    a {
        font: 20px 'Josefin Sans', arial, helvetica, sans-serif;
        display: inline-block;
        color: #bbb;
        text-decoration: none;
        height: 48px;
        line-height: 52px;
        padding: 0 16px;

        #paintings &.paintings-active,
        #video &.video-active,
        #about &.about-active,
        #contact &.contact-active {
            color: #fff;
        }
    }
}

.content {
    width: 70vw;
    padding: 20px 0;
    margin: auto;

    &-body {
        width: 450px;
        color: #fff;
        margin: auto;
        font: 20px 'Josefin Sans', arial, helvetica, sans-serif;
        line-height: 1.4em;
    }
}

.center {
    text-align: center;
}

.about-pic {
    width: 400px;
    height: 300px;
    margin-bottom: 25px;
}

a.contact-button {
    display: inline-block;
    color: #fff;
    text-decoration: none;
    padding: 15px 25px;
    margin-top: 10px;
    border: 1px solid #fff;
    border-radius: 5px;
    background: transparent;
}

.gallery > .gallery__list {
    display: grid;
    grid-template-columns: repeat(5, minmax(auto, 1fr));
    grid-template-rows: repeat(15, 200px);
    grid-gap: 10px;
    grid-auto-flow: dense;
    margin: 0;
    padding: 0;

    li {
        list-style: none !important;
    }
}

.gallery > .gallery__list > li:nth-child(6),
.gallery > .gallery__list > li:nth-child(9),
.gallery > .gallery__list > li:nth-child(10),
.gallery > .gallery__list > li:nth-child(15),
.gallery > .gallery__list > li:nth-child(20),
.gallery > .gallery__list > li:nth-child(33),
.gallery > .gallery__list > li:nth-child(41),
.gallery > .gallery__list > li:nth-child(42) {
    grid-column: span 2;
    grid-row: span 1;
}

.gallery > .gallery__list > li:nth-child(30),
.gallery > .gallery__list > li:nth-child(43) {
    grid-column: span 1;
    grid-row: span 2;
}

.gallery > .gallery__list > li:nth-child(1),
.gallery > .gallery__list > li:nth-child(32) {
    grid-column: span 3;
    grid-row: span 1;
}

.gallery > .gallery__list > li:nth-child(4),
.gallery > .gallery__list > li:nth-child(11),
.gallery > .gallery__list > li:nth-child(28) {
    grid-column: span 2;
    grid-row: span 2;
}

.gallery > .gallery__list > li:nth-child(35) {
    grid-column: span 1;
    grid-row: span 3;
}


.gallery > .gallery__list > li > a {
    display: block;
    margin: 0;
    height: 100%;
}

.gallery > .gallery__list > li > a > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.gallery > .gallery__list > li:nth-child(2),
.gallery > .gallery__list > li:nth-child(8),
.gallery > .gallery__list > li:nth-child(11),
.gallery > .gallery__list > li:nth-child(18),
.gallery > .gallery__list > li:nth-child(23),
.gallery > .gallery__list > li:nth-child(27),
.gallery > .gallery__list > li:nth-child(28) {
    img {
        object-position: top;
    }
}

.gallery > .gallery__list > li:nth-child(4),
.gallery > .gallery__list > li:nth-child(6),
.gallery > .gallery__list > li:nth-child(21),
.gallery > .gallery__list > li:nth-child(25) {
    img {
        object-position: right;
    }
}

.gallery > .gallery__list > li:nth-child(49) {
    img {
        object-position: left;
    }
}

.featherlight .featherlight-content {
    padding: 0 !important;
    border-bottom: 0;
}

.featherlight-previous {
    top: 0;
    left: 0;

    &:hover {
        background: transparent;
    }

    span {
        color: rgba(255,255,255,.3);
        text-shadow: none;
        width: 60px;
        font-size: 50px;
    }
}

.featherlight-next {
    top: 0;
    right: 0;

    &:hover {
        background: transparent;
    }

    span {
        color: rgba(255,255,255,.3);
        text-shadow: none;
        width: 60px;
        font-size: 50px;
    }
}

.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; height: auto; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }